<template>
  <div class="content">
    <Header/>

    <Suspense>
      <router-view/>
    </Suspense>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import Header from '@/components/Header.vue';
import { useRouter } from 'vue-router';
import useUser from './store/user';

export default defineComponent({
  components: {
    Header,
  },
  setup() {
    const router = useRouter();
    const { isAuthenticated } = useUser();

    watch(isAuthenticated, () => {
      router.push({ name: 'Home' });
    });

    return {};
  },
});
</script>

<style lang="scss">
.content {
  position: relative;
  margin-top: calc(#{$header-height});
  padding: 20px;
  // min-width: 100vh;
}
</style>

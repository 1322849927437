import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import useUser from './store/user';

const { watchUser } = useUser();

watchUser();

createApp(App)
  .use(router)
  .mount('#app');

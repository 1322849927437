<template>
  <div class="home">
    <h1>Admin</h1>

    <Suspense>
      <template #default>
        <OrderPreview/>
      </template>

      <template #fallback>
        <Loader/>
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import OrderPreview from '@/components/OrderPreview.vue';
import Loader from '@/components/Loader.vue';

export default defineComponent({
  components: {
    OrderPreview,
    Loader,
  },
  setup() {
    return {
    };
  },
});
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
}
</style>

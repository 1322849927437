import firebase from 'firebase';
import { auth } from '@/firebase';

export function signIn(email: string, password: string): Promise<void> {
  return new Promise((resolve, reject) => {
    auth.signInWithEmailAndPassword(email, password)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signOut(): Promise<void> {
  return new Promise((resolve, reject) => {
    auth.signOut()
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function onAuthStateChanged(callback: (a: firebase.User | null) => void): void {
  auth.onAuthStateChanged(callback);
}

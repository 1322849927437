
import moment from 'moment';
import useOrder from '@/store/order';
import { defineComponent, reactive } from 'vue';
import { Order, OrderStates } from '@/types/functions';

export default defineComponent({
  async setup() {
    const { getOrders, getLastOrder, orders } = useOrder();
    let order = reactive({} as Order);

    await getOrders();
    order = getLastOrder() || {} as Order;

    return {
      getLastOrder,
      OrderStates,
      moment,
      order,
      orders,
    };
  },
});

<template>
  <h2>Poslední objednávka</h2>

  <div class="last-order">
    <div class="last-order__date">
      <span><b>{{ moment(order.created).format('DD.MM.YYYY HH:mm') }}</b></span>
    </div>

    <div class="last-order__main-info">
      <div class="last-order__order last-order__order--name">
        <span>{{ order.name }}</span>
      </div>

      <div class="last-order__order last-order__order--email">
        <span><a :href="`mailto:${order.email}`">{{ order.email }}</a></span>
      </div>

      <div class="last-order__order last-order__order--phone">
        <span><a :href="`tel:${order.phone}`">{{ order.phone }}</a></span>
      </div>

      <div class="last-order__order last-order__order--result-type">
        <span v-if="order.resultType === 'NOT_SELECTED'">Nevybráno</span>
        <span v-else-if="order.resultType === 'PERSON_CONSULTATION'">Osobní</span>
        <span v-if="order.resultType === 'PHONE_CONSULTATION'">Telefonický</span>
        <span v-if="order.resultType === 'EMAIL_CONSULTATION'">Do e-mailu</span>
      </div>

      <div class="last-order__order last-order__order--state">
        <span v-if="order.state === OrderStates.PAID" style="color: green;">Zaplaceno</span>
        <span v-else-if="order.state === OrderStates.NEW" style="color: orange;">Nová objednávka</span>
        <span v-if="order.state === OrderStates.PAYMENT_FAILED" style="color: red;">Platba se nazdařila</span>
        <span v-if="order.state === OrderStates.PAYMENT_PROCESSING" style="color: grey;">Platba probíhá...</span>
      </div>
    </div>

    <div class="last-order__payment">
      <span>ID Stripe transakce: <b>{{ order.payment || '---' }}</b></span>
    </div>
  </div>

  <router-link :to="{ name: 'Orders' }" class="button order-preview__button">Přejít na přehled všech objednávek</router-link>

  <div class="order-count order-count--border-top">
    <h2>Celkový počet objednávek</h2>
    <span>{{ orders.length }}</span>
  </div>

  <div class="order-count order-count--warning">
    <h2>Počet nových objednávek</h2>
    <span>{{ orders.filter((order) => order.state === OrderStates.NEW).length }}</span>
  </div>

  <div class="order-count order-count--success">
    <h2>Počet zaplacených objednávek</h2>
    <span>{{ orders.filter((order) => order.state === OrderStates.PAID).length }}</span>
  </div>

  <div class="order-count order-count--error">
    <h2>Počet objednávek s neúspěšnou platbou</h2>
    <span>{{ orders.filter((order) => order.state === OrderStates.PAYMENT_FAILED).length }}</span>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import useOrder from '@/store/order';
import { defineComponent, reactive } from 'vue';
import { Order, OrderStates } from '@/types/functions';

export default defineComponent({
  async setup() {
    const { getOrders, getLastOrder, orders } = useOrder();
    let order = reactive({} as Order);

    await getOrders();
    order = getLastOrder() || {} as Order;

    return {
      getLastOrder,
      OrderStates,
      moment,
      order,
      orders,
    };
  },
});
</script>

<style lang="scss" scoped>
.order-preview {
  &__button {
    margin: 20px 0;
  }
}

.last-order {
  background-color: $color-light-grey;
  border-radius: 5px;
  margin-bottom: 30px;

  span {
    display: block;
    overflow: scroll;
    word-break: break-word;
    text-align: left;
  }

  &__main-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__order {
    overflow: hidden;
    padding: 20px 10px;
    border-right: 1px solid rgba($color-dark-grey, 0.2);
    white-space: nowrap;

    a {
      color: $color-blue;

      &:hover {
        color: $color-yellow;
      }
    }

    @media screen and (max-width: 600px) {
      border-right: none;
      padding: 5px 5px;
    }

    &:last-child {
      border-right: none;
    }

    &--name {
      flex: 0 0 calc(20% - 20px);
    }

    &--email {
      flex: 0 0 calc(20% - 20px);
    }

    &--phone {
      flex: 0 0 calc(20% - 20px);
    }

    &--result-type {
      flex: 0 0 calc(20% - 20px);
    }

    &--state {
      flex: 0 0 calc(20% - 20px);
    }
  }

  &__date {
    border-bottom: 1px solid rgba($color-dark-grey, 0.2);
    padding: 10px;
  }

  &__payment {
    border-top: 1px solid rgba($color-dark-grey, 0.2);
    padding: 10px;

    span {
      overflow: scroll !important;
    }
  }
}

.order-count {
  span {
    padding: 5px 20px;
    background-color: $color-blue;
    border-radius: 5px;
    color: white;
  }

  &--border-top {
    border-top: 1px solid rgba($color-dark-grey, 0.2);
  }

  &--success {
    span {
      background-color: $color-green;
    }
  }

  &--warning {
    span {
      background-color: $color-yellow;
    }
  }

  &--error {
    span {
      background-color: $color-red;
    }
  }
}
</style>

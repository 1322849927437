import { ref, Ref } from 'vue';
import * as OrderService from '@/services/Order';
import { OrderWithId, Order } from '@/types/functions';

const orders = ref([] as OrderWithId[]);

export default function useOrder(): {
  orders: Ref<OrderWithId[]>;
  getOrders: () => Promise<void>;
  getLastOrder: () => Order | null;
  } {
  async function getOrders(): Promise<void> {
    const data = await OrderService.getOrders();

    orders.value = data;
  }

  function getLastOrder(): Order | null {
    return orders.value[0] || null;
  }

  return {
    orders,
    getOrders,
    getLastOrder,
  };
}

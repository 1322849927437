<template>
  <header ref="header" class="header">
    <div class="header__wrapper">
      <nav class="header__nav header__nav--desktop">
        <router-link :to="{ name: 'Home' }" class="header__nav-box">
          <div class="header__nav-logo">
            <img alt="Logo" :src="homeSvg" width="34"/>
          </div>
          <span class="header__hidden-link-name">
            DOMŮ
          </span>
        </router-link>

        <ul class="header__nav-items header__nav-box--nav">
          <li
            v-for="({ name, text, isActive }, index) in nav"
            :key="`nav-desktop-${name}-${index}`"
            :class="{ 'header__nav-item--active': isActive }"
            class="header__nav-item"
          >
            <router-link :to="{ name }">{{ text }}</router-link>
          </li>

          <li @click="logout" class="header__nav-item header__nav-item--logout">
            LOGOUT
          </li>
        </ul>

        <a
          href="mailto:support@codeccino.com"
          rel="noopener noreferrer"
          class="header__nav-box header__nav-contact"
        >
          <div class="header__nav-logo">
            <img alt="Podpora" :src="supportSvg" width="34"/>
          </div>
          <span class="header__hidden-link-name">
            PODPORA
          </span>
        </a>
      </nav>

      <nav class="header__nav header__nav--mobile">
        <button @click="toggleMobileNav()" class="header__nav-box header__nav-burger">
          <span/>
          <span/>
          <span class="header__hidden-link-name">
            MENU
          </span>
        </button>

        <router-link
          :to="{ name: 'Home' }"
          @click="toggleMobileNav(false)"
          class="header__nav-home header__nav-home--mobile header__nav-box"
        >
          <div @click="toggleMobileNav(false)" class="header__nav-logo">
            <img alt="logo" :src="homeSvg" width="30"/>
          </div>
          <span class="header__hidden-link-name">
            DOMŮ
          </span>
        </router-link>

        <a
          @click="toggleMobileNav(false)"
          href="mailto:support@codeccino.com"
          rel="noopener noreferrer"
          class="header__nav-contact header__nav-box"
        >
          <div @click="toggleMobileNav(false)" class="header__nav-logo">
            <img alt="podpora" :src="supportSvg" width="30"/>
          </div>
          <span class="header__hidden-link-name">
            Podpora
          </span>
        </a>
      </nav>

      <nav ref="mobileNav" class="header__nav-mobile">
        <ul class="header__nav-items header__nav-items--mobile">
          <li
            v-for="({ name, text, isActive }, index) in nav"
            :key="`nav-desktop-${name}-${index}`"
            :class="{ 'header__nav-item--active': isActive }"
            @click="toggleMobileNav(false)"
            class="header__nav-item"
          >
            <router-link
              :to="{ name }"
              @click="toggleMobileNav(false)"
            >
              {{ text }}
            </router-link>
          </li>

          <li @click="logout" class="header__nav-item">
            LOGOUT
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import homeSvg from '@/assets/home.svg';
import supportSvg from '@/assets/support.svg';
import { signOut } from '@/services/User';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const mobileNav = ref(document.createElement('nav'));
    const header = ref(document.createElement('header'));
    const nav = computed(() => [
      { name: 'Orders', text: 'OBEDNÁVKY', isActive: route.name === 'Orders' },
      { name: 'Texts', text: 'TEXTY', isActive: route.name === 'Texts' },
    ]);

    function toggleMobileNav(toggle = true) {
      if (toggle) {
        mobileNav.value.classList.toggle('header__nav-mobile--visible');
        header.value.classList.toggle('header--mobile--visible');
        document.body.classList.toggle('no-scroll');
      } else {
        mobileNav.value.classList.remove('header__nav-mobile--visible');
        header.value.classList.remove('header--mobile--visible');
        document.body.classList.remove('no-scroll');
      }
    }

    async function logout() {
      await signOut();

      router.go(0);
    }

    return {
      homeSvg,
      supportSvg,
      nav,
      toggleMobileNav,
      mobileNav,
      header,
      logout,
    };
  },
});
</script>

<style lang="scss">
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: $header-height;
  padding: 0 $header-padding;
  transition: all .5s ease;
  background-color: $color-dark-grey;

  &--mobile--visible {
    background-color: $color-blue;
  }

  &__wrapper {
    margin: 0 0 $section-margin 0;
  }

  &__hidden-link-name {
    position: absolute;
    top: 0;
    line-height: 0;
    font-size: 0;
    color: transparent;
    width: 0 !important;
  }

  &__nav {
    max-width: $app-width;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-content: center;
    position: relative;
    z-index: 1000;

    &--mobile {
      display: none;

      @media screen and (max-width: 740px) {
        display: flex;
      }
    }

    &--desktop {
      @media screen and (max-width: 740px) {
        display: none;
      }
    }
  }

  &__nav-mobile {
    display: block;
    position: fixed;
    top: calc(#{$header-height} - 1px);
    right: 0;
    width: 100%;
    height: calc(100vh - #{$header-height} + 1px);
    background-color: $color-blue;
    transform: translateY(-100vh);
    transition: all .5s ease;
    z-index: 100;

    &--visible {
      background-color: $color-blue;
      transform: translateY(0);
    }
  }

  &__nav-items {
    display: flex;
    justify-content: center;
    align-content: center;

    &--mobile {
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: auto;

      @media screen and (max-width: 740px) {
        display: flex;
        max-width: none;
        margin-top: 30px;
      }

      button {
        border: none;
      }
    }
  }

  &__nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;

    @media screen and (max-width: 740px) {
      margin-right: 0;
      margin-bottom: 30px;
    }

    &--logout {
      color: $color-white;
      cursor: pointer;
    }

    &:last-child {
      margin: 0;
    }

    a {
      color: $color-white;
    }

    &--active {
      a {
        color: $color-yellow;
      }
    }
  }

  &__nav-box {
    position: relative;
    height: $header-height;
    padding: 10px;
    box-sizing: border-box;

    &--nav {
      width: 50%;
    }
  }

  &__nav-contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $color-white;

    @media screen and (max-width: 740px) {
      width: 50px;
    }
  }

  &__nav-burger {
    background: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50px;

    span {
      width: 100%;
      height: 2px;
      background-color: #ffffff;
      display: block;
    }
  }
}
</style>


import { defineComponent } from 'vue';
import OrderPreview from '@/components/OrderPreview.vue';
import Loader from '@/components/Loader.vue';

export default defineComponent({
  components: {
    OrderPreview,
    Loader,
  },
  setup() {
    return {
    };
  },
});

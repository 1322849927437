
import { defineComponent, watch } from 'vue';
import Header from '@/components/Header.vue';
import { useRouter } from 'vue-router';
import useUser from './store/user';

export default defineComponent({
  components: {
    Header,
  },
  setup() {
    const router = useRouter();
    const { isAuthenticated } = useUser();

    watch(isAuthenticated, () => {
      router.push({ name: 'Home' });
    });

    return {};
  },
});

import {
  createRouter,
  createWebHistory,
  RouteRecordName,
  RouteRecordRaw,
} from 'vue-router';
import useUser from '@/store/user';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/objednavky',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
  },
  {
    path: '/texty',
    name: 'Texts',
    component: () => import(/* webpackChunkName: "texts" */ '../views/Texts.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  const { isAuthenticated, redirect } = useUser();

  if (to.name === 'Login' && isAuthenticated.value) {
    next({ name: 'Home' });
  } else if (to.name !== 'Login' && !isAuthenticated.value) {
    redirect.value = to.name as RouteRecordName;
    next({ name: 'Login' });
  } else next();
});

export default router;

import { ref, Ref } from 'vue';
import { onAuthStateChanged } from '@/services/User';
import { RouteRecordName } from 'vue-router';

const isAuthenticated = ref(false);
const redirect = ref('' as RouteRecordName);

export default function useUser(): {
  isAuthenticated: Ref<boolean>;
  redirect: Ref<RouteRecordName>;
  watchUser: () => void;
  } {
  function watchUser(): void {
    onAuthStateChanged((user) => {
      if (user) isAuthenticated.value = true;
      else isAuthenticated.value = false;
    });
  }

  return {
    isAuthenticated,
    redirect,
    watchUser,
  };
}
